import React from 'react'
import './Home.css'
import Hero from './Hero'
import WhoAreWe from './WhoAreWe'
import WhatWeOffer from './WhatWeOffer'
import Empowering from './Empowering'
import Card from './Card'
import ContactUs from './ContactUs'

function Home() {
  return (
    <>
      <Hero />
      <WhoAreWe />
      <WhatWeOffer />
      <Empowering />
      <Card />
      <ContactUs />
    </>
  )
}
export default Home

