import React, { useEffect } from 'react'
import background from '../../Assets/Hero/home_who_are_we.jpeg'
import line from '../../Assets/icons/line.svg'
import AOS from "aos";
import "aos/dist/aos.css";

function WhoAreWe() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    return (
        <div className="w-full bg-white overflow-hidden" id='who-are-we'>
            <div className="mx-auto max-w-[1640px] lato relative pt-[3rem] pb-[5rem]"
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    minHeight: 'auto',
                }}
            >
                <div className='text-center p-4 md:p-8' >
                    <div className='inline-flex items-center justify-center'>
                        <img src={line} alt="" className="h-1 w-full max-w-[100px] md:max-w-[150px]" data-aos='fade-down' data-aos-delay="200" />
                        <h1 style={{ fontSize: 'clamp(17px,1.2vw,20px)' }} className='uppercase text-[#463820] opacity-[63%] font-semibold mx-2 w-[18rem]' data-aos='fade-up'>who are we</h1>
                        <img src={line} alt="" className="h-1 w-full max-w-[100px] md:max-w-[150px]" data-aos='fade-down' data-aos-delay="200" />
                    </div>
                    <div className='mt-4' data-aos='fade-up' data-aos-delay="200">
                        <h2 style={{ fontSize: 'clamp(24px,2.5vw,40px)' }} className='uppercase text-[#99702B] w-[90%] md:w-[80%] mx-auto font-extrabold'>Our core identity revolves around a profound commitment to IT and E-commerce success.</h2>
                    </div>
                    <div className='mt-4' data-aos='fade-up' data-aos-delay="200">
                        <p style={{ fontSize: 'clamp(16px,2vw,25px)' }} className='text-[#726652] w-[90%] md:w-[80%] mx-auto font-semibold leading-[1.5] md:leading-[1.75]'>
                            Dynamic Leo makes your IT and e-commerce projects our top priority. We convert your concepts into dynamic digital solutions that propel success. Our mastery in software solutions and e-commerce keeps your business at the forefront of digital innovation. As technology evolves, our commitment to excellence stays unwavering.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhoAreWe
