const Navbar = {
    links: [
        {
            name: "Home",
            path: "#home"
        },
        {
            name: "WHO ARE WE",
            path: "#who-are-we"
        },
        {
            name: "WHAT WE OFFER",
            path: "#what-we-offer"
        },
        {
            name: "CONTACT US",
            path: "#contact-us"
        }
    ]
};

export default Navbar;
